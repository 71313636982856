import { connect } from 'react-redux'
import { SavedLTWebsiteAnalytics, SearchThemesAndOtherNews,CheckUserAuthenticity,ValidateLink, CheckUserAuthenticityAndSendToken } from '../../../store/home/duck'
import Header from './component'

const HeaderContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    SavedLTWebsiteAnalytics,
    SearchThemesAndOtherNews,
    CheckUserAuthenticity,
    ValidateLink,
    CheckUserAuthenticityAndSendToken
  }
)(Header)

export default HeaderContainer
