import { connect } from 'react-redux'
import { getLtweeklyData, SavedLTWebsiteAnalytics, updateCurrentPosition, initHomePhase,getLatestLTWeeklyNews,sendPollAnswer,getPollResult,GetTopLtWeeklyTheme,CheckUserAuthenticity, CheckUserAuthenticityAndSendToken,GetLTWeeklyDataNew } from '../../store/home/duck'
import FeedComponent from './Component'

const FeedContainer = connect(
  // Map state to props
  state => ({
    ltweeklyData: state.home.ltweeklyData,
    totalLtweeklyData: state.home.totalLtweeklyData,
    ltweeklyPhase: state.home.ltweeklyPhase,
    currentPosition: state.home.currentPosition,
    totalPageNumber: state.home.totalPageNumber,
    ltweeklyTotalRecords: state.home.ltweeklyTotalRecords
  }),
  // Map actions to dispatch and props
  {
    SavedLTWebsiteAnalytics,
    updateCurrentPosition,
    getLtweeklyData,
    initHomePhase,
    getLatestLTWeeklyNews,
    sendPollAnswer,
    getPollResult,
    GetTopLtWeeklyTheme,
    CheckUserAuthenticity,
    CheckUserAuthenticityAndSendToken,
    GetLTWeeklyDataNew
  }
)(FeedComponent)

export default FeedContainer
