import CryptoJS from 'crypto-js';

export default function decryptedData(){
    const jwtToken = localStorage.getItem('t')

    let userData = {}
    // const jwtToken = cookies.get('Authorization');
    if (jwtToken) {

      const a = jwtToken.split("&") 

      if(a.length>1){
        const token = a[0];
        const tokenb = a[1]?.replaceAll("IVvalu=", "")
        
        const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
        const ciphertextBytes = CryptoJS.enc.Base64.parse(token);
        const hashedKey = CryptoJS.SHA256(encryptionKey);  
  
        const decryptedData = CryptoJS?.AES.decrypt(
          { ciphertext: ciphertextBytes },
          hashedKey,
          {
             iv: CryptoJS.enc.Base64.parse(tokenb)
          }
      );
      
      
      // Convert the decrypted data from WordArray to a UTF-8 string
      const decryptedText = decryptedData?.toString(CryptoJS?.enc.Utf8);
      userData=JSON.parse(decryptedText)
      console.log(userData,"===>?")
      return userData
  
    } else {
      return ""
    }
  }
}
