import { fetch } from "../../utils";
import { get } from "lodash";
import request from "./request";
const HostName = "https://lnt-webapp-uat-001-liontreeapi.azurewebsites.net";
// const HostName = process.env.REACT_APP_API_HOSTNAME;
// const HostName = "https://kindredapp-api.azurewebsites.net"
export const getLtweeklyData = (value) => {
  return fetch(
    `${HostName}/CustomItem/GetLTWeeklies?count=${get(
      value,
      "limit",
      10
    )}&page=${get(value, "pageNumber", 1)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const savedLTWebsiteAnalytics = (value) => {
  let header = {};
  if (
    localStorage.getItem("t") &&
    localStorage.getItem("t") != "?target=" &&
    localStorage.getItem("t") != "?target"
  ) {
    header = {
      "Content-Type": "application/json",
      EncryptionToken: localStorage.getItem("t")
        ? localStorage.getItem("t") != "?target="
          ? localStorage.getItem("t")
          : ""
        : "",
    };
  } else {
    header = {
      "Content-Type": "application/json",
    };
  }

  return fetch(`${HostName}/CustomItem/SavedLTWebsiteAnalytics`, {
    method: "POST",
    headers: header,
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getltWeeklyByDate = (value) => {
  return fetch(
    `${HostName}/CustomItem/GetLTWeeklyByDate?date=${get(
      value,
      "date",
      ""
    )}&direction=${get(value, "direction", "")}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const validateUser = (value) => {
  return fetch(
    `${HostName}/App/ValidateUser?email=${get(value, "email", "")}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLtWeeklyDetailsById = (value) => {
  return fetch(
    `${HostName}/CustomItem/GetLTWeeklyDetails?ItemId=${get(value, "id", "")}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const GetLTWeeklyArchiveList = (value) => {
  return fetch(
    `${HostName}/CustomItem/GetLTWeeklyArchiveListPre?year=${value.year}&page=${value.page}&count=${value.count}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const GetResources = (value) => {
  return fetch(`${HostName}/CustomItem/GetResources`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getCategoriesCount = (value) => {
  return fetch(
    `${HostName}/CustomItem/GetCategoriesCount?assignedDate=${value.assignedDate}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const getOtherNewsByCategories = (value) => {
  return fetch(`${HostName}/CustomItem/GetOtherNewsByCategories`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const SearchThemesAndOtherNews = (value) => {
  return request(
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      //url: `${HostName}/Dashboard/SearchThemesAndOtherNews?Text=${value.Text?.replaceAll(/[^\s]/g,"%")}&Page=${value.Page}&Count=${value.Count}`,
      url: `${HostName}/Dashboard/SearchThemesAndOtherNews`,
      data: JSON.stringify({
        Text: value.Text.replace("’", "%").replaceAll(",", "%"),
        Page: value.Page,
        Count: value.Count,
      }),
    },

    value.token
  )
    .then((data) => data)
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const GetAllLTWeeklyDates = (value) => {
  return fetch(`${HostName}/Dashboard/GetAllLTWeeklyDates`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const GetArticalCategoriesCount = (value) => {
  return fetch(
    `${HostName}/CustomItem/GetArticalCategoriesCount?assignedDate=${value.assignedDate}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getLTWeeklyThemesCategories = (value) => {
  return fetch(`${HostName}/Dashboard/GetLTWeeklyThemesCategories`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getLTWeeklyAndOthersNewsCategories = (value) => {
  return fetch(`${HostName}/Dashboard/GetLTWeeklyAndOthersNewsCategories`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getLatestLTWeeklyNews = (value) => {
  return fetch(
    `${HostName}/Dashboard/GetLatestLTWeeklyNews?page=${value.page}&count=${value.count}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const sendPollAnswer = (value) => {
  let header = {};
  if (
    localStorage.getItem("t") &&
    localStorage.getItem("t") != "?target=" &&
    localStorage.getItem("t") != "?target"
  ) {
    header = {
      "Content-Type": "application/json",
      EncryptionToken: localStorage.getItem("t")
        ? localStorage.getItem("t") != "?target="
          ? localStorage.getItem("t")
          : ""
        : "",
    };
  } else {
    header = {
      "Content-Type": "application/json",
    };
  }

  return fetch(`${HostName}/Poll/PollSubmit`, {
    method: "POST",
    headers: header,
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const getPollResult = (value) => {
  return fetch(`${HostName}/Poll/GetPollResult`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const GetTopLtWeeklyTheme = (value) => {
  return fetch(`${HostName}/Dashboard/GetTopLtWeeklyTheme?page=${value.page}&count=${value.count}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const unsubscribeUser = (value) => {
  return fetch(`${HostName}/Dashboard/UnsubscribeUser?Email=${value}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const CheckUserAuthenticity = (value) => {
  return fetch(`${HostName}/Gated/CheckUserAuthenticityAndThenSendLink`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};
export const ValidateLink = (value) => {
  console.log(localStorage.getItem("t"))
  return fetch(`${HostName}/Gated/ValidateGatedLink`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Encryptiontoken":localStorage.getItem("t")
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

export const CheckUserAuthenticityAndSendToken = (value) => {
  return fetch(`${HostName}/Gated/CheckUserAuthenticityAndSendToken`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(value),
  })
    .then((res) => {
      return res.json();
    })
    .then((payload) => {
      return payload;
    })
    .catch((error) => {
      throw error;
    });
};

