import React, { createContext, useState, useContext,useEffect } from 'react';
import decryptedData from "./decryption";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('t') || null);
  const [userEmailId, setUserEmailId] = useState("");
  const [userName, setUserName] = useState("");

  const login = (newToken) => {
    localStorage.setItem('t', newToken);
    setToken(newToken);
  };

  const logout = () => {
    localStorage.removeItem('t');
    setToken(null);
  };
  useEffect(() => {
    const checkTokenAndFetchData = async () => {
      const token = localStorage.getItem("t");
  
      if (!token) {
        // If no token, user is not authenticated; show login options
        setUserEmailId(null);
        setUserName(null);
        return;
      }
  
      // Call decryption function to get user details
      const userData = await decryptedData();
      console.log(userData,"userdataaftertoken")
  
      if (userData && userData.Email) {
        // Token is valid; set user information in state
        setUserEmailId(userData.Email);
        setUserName(userData.UserName);
      } else {
        // Token exists but decryption failed; reset user state
        setUserEmailId(null);
        setUserName(null);
      }
    };
  
    checkTokenAndFetchData();
  }, [token]);
  
  return (
    <AuthContext.Provider value={{ token, login, logout, userEmailId,
      userName}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
