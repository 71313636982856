import { connect } from 'react-redux'
import { getLTWeeklyArchiveList, SavedLTWebsiteAnalytics,CheckUserAuthenticity, CheckUserAuthenticityAndSendToken } from '../../store/home/duck'
import ArchivesComponent from './component'

const ArchivesContainer = connect(
  // Map state to props
  (/*state*/) => ({}),
  // Map actions to dispatch and props
  {
    SavedLTWebsiteAnalytics,
    getLTWeeklyArchiveList,
    CheckUserAuthenticity,
    CheckUserAuthenticityAndSendToken
  }
)(ArchivesComponent)

export default ArchivesContainer
