import React, { useState, useEffect,useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import "./styles.scss";
import { get, orderBy } from "lodash";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { browserName } from "react-device-detect";
import { getDevice } from "../../constants/constant";
import ReactModal from "react-modal";
import { Pdf } from "../../components/icons";
import { useAuth } from "../../utils/AuthContext";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
);
const HOST_URL = process.env.REACT_APP_API_HOSTNAME;

export default function ArchivesComponent(props) {
  const [isMounted, setIsMounted] = useState(false);
  const [year, setYear] = useState("");
  const [isLoader, setIsLoader] = useState(true);
  const [archiveList, setArchiveList] = useState([]);
  const [mailModalIsOpen,setMailModalIsOpen] =useState(false)
  // const [successModalIsOpen,setSuccessModalIsOpen] =useState(false)
const [themeLink,setThemeLink]= useState("")
const [userEmail,setUserEmail]= useState("")
const [errorMessage,setErrorMessage]=useState("")
const [emailloader,setEmailLoader]=useState(false)
const [isAcknwoledgmentModalOpen,setIsAcknwoledgmentModalOpen] = useState(false)
const [isLoading, setIsLoading] = useState(false);
const [totalRecords, setTotalRecords] = useState(0);
const [pageNumber, setPageNumber] = useState(1);
const [limit, setLimit] = useState(10);
const [isButtonDisabled,setIsButtonDisabled]=useState(false)
const emailInputRef = useRef(null);
const { login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handledClicked = (actionDescription, year, actionType) => {
    let itemDate = window.location.pathname.split("/")[2];
    // let itemId = window.location.pathname.split("/")[3];
    // itemId = itemId ? itemId : "";
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(itemDate, "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url:
        window.location.href
          .replace("https://", "")
          .replace("http://", "")
          .split("20")[0] + year,
      ActionType: actionType,
      NewsletterID:  "00000000-0000-0000-0000-000000000000",
      NewsletterDate: itemDate,
      ThemeID: "00000000-0000-0000-0000-000000000000",
      ActionDescription: actionDescription,
      Device: getDevice(),
      Browser: browserName,
      ArticleTitle:actionDescription
    };
    props.SavedLTWebsiteAnalytics(obj);
  };
const handleClickPdf=(actionDescription, val)=>{
  let format = "MMMM DD, YYYY";
  let currentUtcTime = new Date();
  // Converts the UTC time to a locale specific format, including adjusting for timezone.
  let currentDateTimeCentralTimeZone = new Date(
    currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
  );
  let dayNight =
    currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
  let currentDate = moment(new Date(), format).format(format);
  let currentHr = currentDateTimeCentralTimeZone.getHours();
  let currentMin = currentDateTimeCentralTimeZone.getMinutes();
  currentDate =
    currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
  let obj = {
    Date: currentDate,
    Url: val.replace("https://", "").replace("http://", ""),
    ActionType: "clicked",
    NewsletterID: "00000000-0000-0000-0000-000000000000",
    NewsletterDate: "",
    ThemeID: "00000000-0000-0000-0000-000000000000",
    ActionDescription: actionDescription,
    Device: getDevice(),
    Browser: browserName,
    ArticleTitle:actionDescription
  };
  props.SavedLTWebsiteAnalytics(obj);
  window.open(val, "_blank");
}
  const linkClicked = (actionDescription, url, mainId,itemName="") => {
    let articleTitle = ""
    let itemDate = url.split("/")[2];
    let itemId = url.split("/")[3];
    itemId = itemId ? itemId : "";
    actionDescription = getThemeTitle(actionDescription);
    if (
      actionDescription !== "LTWeekly Detail" &&
      actionDescription !== "Stock Market Check" &&
      actionDescription !== "This Week's Other Curated News" &&
      actionDescription !== "Intro"
    ) {
      articleTitle = actionDescription
      actionDescription = "Theme Title - " + actionDescription;
    }
    itemDate =
      itemDate &&
      itemDate !== "archives" &&
      itemDate !== "about" &&
      itemDate !== undefined
        ? moment(new Date(itemDate), "MM DD YYYY").format("MMMM DD, YYYY")
        : "";
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    itemId =
      actionDescription !== "LTWeekly Detail" &&
      actionDescription !== "Stock Market Check" &&
      actionDescription !== "This Week's Other Curated News" &&
      actionDescription !== "Intro"
        ? itemId
        : "";
    let obj = {
      Date: currentDate,
      Url: window.location.host + url,
      ActionType: "clicked",
      NewsletterID: mainId,
      NewsletterDate: itemDate,
      ThemeID: itemId,
      ActionDescription: actionDescription,
      Device: getDevice(),
      Browser: browserName,
      ArticleTitle : articleTitle?articleTitle:actionDescription,
      ThemeIndex:itemName
    };
    props.SavedLTWebsiteAnalytics(obj);
    // if(isEmailExists){
    //   window.location.href=url
    // }
  };

  const RadioInput = ({ label, value, checked, type, setter }) => {
    return (
      <>
        <input
          className="custom__radio__group--button"
          id={label}
          type="radio"
          name="year"
          onChange={() => setter(value)}
          checked={checked === value}
        />
        <label className="custom__radio__group--label" htmlFor={label}>
          {label}
        </label>
      </>
    );
  };

  useEffect(() => {
    
    const { pathname } = location;
    if (!isMounted) {
      const isMobile =getDevice()
    if (isMobile && !(isMobile ==="Android"||isMobile==="ios")){
      window.scrollTo({ top: 0 });      
    }
      const fetchData = async () => {
        let { value } = await props.getLTWeeklyArchiveList({
          year: pathname.split("/")[3],
          page:pageNumber,
          count:5
        });
        setYear(pathname.split("/")[3]);
        setArchiveList(get(value, "results", ""));
        setTotalRecords(get(value,"Count",""))
        setIsLoader(false);
      };
      fetchData();
      setIsMounted(true);
      let yr =
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ];
      handledClicked("Archive Page", yr, "pageview");
    }
  }, [archiveList, isMounted, location, props]);

  // useEffect(() => {
  //   window.scrollTo({ top: 0 });
  // }, []);

  const onChangeYear = async (year) => {
    handledClicked(`Showing LT weekly ${year}`, year, "clicked");
    setPageNumber(1)
    setYear(year);
    setIsLoader(true);
    let { value } = await props.getLTWeeklyArchiveList({ year: year,page:1,count:5 });
    setArchiveList(get(value, "results", ""));
    setTotalRecords(get(value,"Count",""))
    setIsLoader(false);
    navigate(`/LTWeekly/archives/${year}`, { state: {} });
  };

  const getThemeTitle = (title) => {
    if (title.includes("<span")) {
      title = title.split("<span>")[1];
      title = title.split("</span>")[0];
      if (title.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
        if (title.includes("<i>")) {
          let first = title?.split("<i>")[0];
          let second = title?.split("<i>")[1];
          title = first + " " + second;
          let firstI = title?.split("</i>")[0];
          let secondI = title?.split("</i>")[1];
          title = firstI + " " + secondI;
        }
        return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
      } else {
        if (title.includes("<p")) {
          let doc = new DOMParser().parseFromString(title, "text/html");
          title = doc.body.firstChild.innerHTML;
          if (title.includes("<a")) {
            let doc = new DOMParser().parseFromString(title, "text/html");
            title = doc.body.firstChild.innerHTML;
          }
          if (title.includes("<i>")) {
            let first = title?.split("<i>")[0];
            let second = title?.split("<i>")[1];
            title = first + " " + second;
            let firstI = title?.split("</i>")[0];
            let secondI = title?.split("</i>")[1];
            title = firstI + " " + secondI;
          }
          return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        } else {
          return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
        }
      }
    } else if (title.includes("<a")) {
      if (title.includes("<p")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else if (title.includes("<p")) {
      let doc = new DOMParser().parseFromString(title, "text/html");
      title = doc.body.firstChild.innerHTML;
      if (title.includes("<a")) {
        let doc = new DOMParser().parseFromString(title, "text/html");
        title = doc.body.firstChild.innerHTML;
      }
      if (title.includes("<i>")) {
        let first = title?.split("<i>")[0];
        let second = title?.split("<i>")[1];
        title = first + " " + second;
        let firstI = title?.split("</i>")[0];
        let secondI = title?.split("</i>")[1];
        title = firstI + " " + secondI;
      }
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    } else {
      return title.replaceAll("&amp;", "&").replaceAll("&nbsp;", " ");
    }
  };

  const yearsObject = [
    { key: "2024", value: "2024" },
    { key: "2023", value: "2023" },
    { key: "2022", value: "2022" },
    { key: "2021", value: "2021" },
  ];
  const closeMailModal = () => {
    setMailModalIsOpen(false)
    setErrorMessage("")
    setThemeLink("")
    setUserEmail("")
    setIsButtonDisabled(false)
   };
   const openMailModal = (getLink) => {
     setThemeLink(getLink)
     setMailModalIsOpen(true)
   };
  //  const closeSuccessModal = () => {
  //   setUserEmail("")
  //   setSuccessModalIsOpen(false)
  //  };
  //  const openSuccessModal = () => {
  //    setSuccessModalIsOpen(true)
  //  };
   const checkUser= async (email,link)=>{
    setIsButtonDisabled(true)
     if(email===""){
       setErrorMessage('Please enter email')
       setIsButtonDisabled(false)
       return
     }
     let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     if (!regEmail.test(email)) {
       setErrorMessage('Invalid Email Address')
       setIsButtonDisabled(false)
       return
     }
     let obj ={
       email:email,
       link:link?link:"",
     }
     setEmailLoader(true)
     const {value}=await props.CheckUserAuthenticityAndSendToken(obj)
     if (value?.Success){
      setEmailLoader(false)
      if(value.Message==="User is present"){
        let token = value.Result.replaceAll("?t=","")
      login(token);
      setEmailLoader(false)
      setMailModalIsOpen(false)
        }
        else{
          opneAcknwoledgmentModal()
        }

     }else{
      setEmailLoader(false)
     window.location.href = 'https://liontreeuat.wpenginepowered.com/ltweekly/?inboundpath=themeweb'
       setUserEmail("")
       setMailModalIsOpen(false)
     }
     setMailModalIsOpen(false)
     setErrorMessage("")
     setIsButtonDisabled(false)
     setEmailLoader(false)
   }
   const handleChangeEmail =(email) =>{
     const userEmail=email
     setUserEmail(userEmail)
     setErrorMessage("")
   }
   const opneAcknwoledgmentModal =()=>{
    setIsAcknwoledgmentModalOpen(true)
  }
  const closeAcknowledgmentModal=()=>{
    setIsAcknwoledgmentModalOpen(false)
  }
  
  const contentClickHandler = (data) => {
    navigate(
      `/LTWeekly/${moment(get(data, "ItemName", ""), "MM DD YYYY").format(
        "MMMM-DD-YYYY"
      )}/${get(data, "ItemID", "")}`
      // { state: { data: data, isFrom: "feed" } }
    );
    // window.location.reload(true);
  }

  const getLongDescription = (str, data) => {
    str = str?.substr(0, 500);
    if (str?.includes("<a")) {
      return str
        .substr(0, 375)
        .concat(
          "... ",
          `<a href='/LTWeekly/${moment(
            get(data, "ItemName", ""),
            "MM DD YYYY"
          ).format("MMMM-DD-YYYY")}/${get(
            data,
            "ItemID",
            ""
          )}' class="read-more-link" onclick="return false">read more</a>`
        )
        .replace("", "");
    } else {
      return str
        ?.substr(0, 200)
        .concat(
          "... ",
          `<a href='/LTWeekly/${moment(
            get(data, "ItemName", ""),
            "MM DD YYYY"
          ).format("MMMM-DD-YYYY")}/${get(
            data,
            "ItemID",
            ""
          )}' class="read-more-link" onclick="return false">read more</a>`
        )
        .replace("", "");
    }
  };
  const loadMore = async () => {
    const { pathname } = location;
    let format = "MMMM DD, YYYY";
    let currentUtcTime = new Date();
    // Converts the UTC time to a locale specific format, including adjusting for timezone.
    let currentDateTimeCentralTimeZone = new Date(
      currentUtcTime.toLocaleString("en-US", { timeZone: "America/New_York" })
    );
    let dayNight =
      currentDateTimeCentralTimeZone.getHours() >= 12 ? "pm" : "am";
    let currentDate = moment(new Date(), format).format(format);
    let currentHr = currentDateTimeCentralTimeZone.getHours();
    let currentMin = currentDateTimeCentralTimeZone.getMinutes();
    currentDate =
      currentDate + " at " + currentHr + ":" + currentMin + dayNight + " EST";
    let obj = {
      Date: currentDate,
      Url: window.location.href.replace("https://", "").replace("http://", ""),
      ActionType: "clicked",
      NewsletterID: "00000000-0000-0000-0000-000000000000",
      NewsletterDate: "",
      ThemeID: "00000000-0000-0000-0000-000000000000",
      ActionDescription: "Load More Click",
      Device: getDevice(),
      Browser: browserName,
      ArticleTitle:"Load More Click"
    };
    props.SavedLTWebsiteAnalytics(obj);
    setIsLoading(true);
    let newNumber = pageNumber + 1;
    const { value } = await props.getLTWeeklyArchiveList({
      year:pathname.split("/")[3] ,
      page: newNumber,
      count:5
    });
    let concateData = [...archiveList, ...get(value, "results", [])];
    setArchiveList([...concateData])
    setPageNumber(newNumber);
    setIsLoading(false)
  };
  const handleAfterOpenModal = () => {
    if (emailInputRef.current) {
      emailInputRef.current.focus(); 
    }
  };

  return (
    <>
      <section className="section__archives">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="archives__container">
                <div className="sarchives__year">
                  <div className="custom__radio__group">
                    {yearsObject.map((data, i) => {
                      return (
                        <div key={i} className="custom__radio__group">
                          <RadioInput
                            value={data.key}
                            setter={onChangeYear}
                            checked={year}
                            label={data.value}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="archives__list">
                  <>
                    {isLoader ? (
                      <>
                        <h1 style={{ marginTop: 200 }}> </h1>
                        <Spin
                          size="large"
                          indicator={
                            <LoadingOutlined
                              style={{ fontSize: 30, color: "#000" }}
                              spin
                            />
                          }
                          className="loader__full"
                        />
                      </>
                    ) : (
                      archiveList.map((item, i) => {
                        item.ChildList = orderBy(
                          item.ChildList,
                          (items) => parseInt(items.ItemName),
                          ["asc"]
                        );
                        return (
                          <div className="archives__list--item" key={i}>
                            <div className="feed__header__Archieve">
                      <h1 className="feed__title">
                        LT Weekly:{" "}
                        {moment(get(item, "ItemName", ""), "MM DD YYYY").format(
                          "MMM D, YYYY"
                        )}
                      </h1>
                      {/* 
                         `${HOST_URL}/api/utility/pdf?path=%2fsitecore%2fcontent%2fKindredApp3%2fLT+Weekly%2f${data.ItemName}`,
                      */}
                      
                      <a
                       onClick={(e) =>{
                        e.preventDefault();
                        if (!localStorage.getItem("t")){
                          openMailModal( `${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                            get(item, "ItemName", ""),
                            "MM DD YYYY"
                          ).format("MMMM-DD-YYYY")}/${get(
                            item,
                            "ItemID",
                            ""
                          )}`)
                        }else{
                          
                          handleClickPdf(
                          "Download Pdf",
                          
                          `${HOST_URL}/Dashboard/pdf?WeeklyDate=${item.ItemName}`
                        )
                        
                      }}}
                        // href="javascript:void(0)"
                        className="feed__download"
                        // target="_blank"
                        rel="noreferrer"
                      >
                        
                        <Pdf />
                      </a>
                      
                    </div>
                            <ul className="archives__topics">
                              <li
                                key={get(item, "ItemId", "") + 0}
                                className="archives__topic--item"
                              >
                                <div className="New-Archieve-WeeklyImage">
                                  <img src={item.Photo} />
                                </div>
                                <div className="read-more-content">
                        <div
                          onClick={() => {
                            if(!localStorage.getItem("t")){
                              openMailModal( `${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                get(item, "ItemName", ""),
                                "MM DD YYYY"
                              ).format("MMMM-DD-YYYY")}/${get(
                                item,
                                "ItemID",
                                ""
                              )}`)
                            }else{
                            linkClicked(
                              "Read more",
                              `/LTWeekly/${moment(
                                get(item, "ItemName", ""),
                                "MM DD YYYY"
                              ).format("MMMM-DD-YYYY")}/${get(
                                item,
                                "ItemID",
                                ""
                              )}`,
                              get(item, "ItemID", "")
                            );
                            
                            contentClickHandler(item);
                          }}}
                          className="feed__editor graphik-para"
                          dangerouslySetInnerHTML={{
                            __html: getLongDescription(
                              item.LongDescription,
                              item
                            ),
                          }}
                        ></div>
                      </div>
                                <Link
                                  className="archives__topic--link"
                                  onClick={() => {
                                    if(!localStorage.getItem("t")){
                                      openMailModal(`${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                        get(item, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        item,
                                        "ItemId",
                                        ""
                                      )}`)
                                    }else{
                                    linkClicked(
                                      "Intro",
                                      `/LTWeekly/${moment(
                                        get(item, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        item,
                                        "ItemId",
                                        ""
                                      )}`,
                                      get(item, "ItemId", "")
                                    );
                                    navigate(
                                      `/LTWeekly/${moment(
                                        get(item, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        item,
                                        "ItemId",
                                        ""
                                      )}`,
                                      { state: { data: item, from: "feed" } }
                                    );
                                    window.location.reload(true);
                                  }}}
                                  to={localStorage.getItem('t')?`/LTWeekly/${moment(
                                        get(item, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        item,
                                        "ItemId",
                                        ""
                                      )}`:`#`}
                                >
                                 {/* {(year==="2024" || year==="2023")? <img src={item.Photo} className="archives-list-img"/>:""} */}
                                  Intro
                                </Link>
                                <ol className="archives__topics--child">
                                  {get(item, "ChildList", []).length > 0 &&
                                    get(item, "ChildList", []).map((it, j) => {
                                      return (
                                        <li
                                          key={j}
                                          className="archives__topic--child-item"
                                        >
                                          <Link
                                            className="archives__topic--child-link"
                                            onClick={() =>{
                                              if(!localStorage.getItem("t")){
                                                openMailModal(`${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                                  get(item, "ItemName", ""),
                                                  "MM DD YYYY"
                                                ).format("MMMM-DD-YYYY")}/${get(
                                                  it,
                                                  "ItemId",
                                                  ""
                                                )}`)
                                              }else{
                                              linkClicked(
                                                get(it, "ShortDescription", ""),
                                                `/LTWeekly/${moment(
                                                  get(item, "ItemName", ""),
                                                  "MM DD YYYY"
                                                ).format("MMMM-DD-YYYY")}/${get(
                                                  it,
                                                  "ItemId",
                                                  ""
                                                )}`,
                                                get(item, "ItemId", ""),
                                                get(it, "ItemName","")
                                              )
                                            
                                            navigate(`/LTWeekly/${moment(
                                              item.ItemName,
                                              "MM DD YYYY"
                                            ).format("MMMM-DD-YYYY")}/${
                                              it.ItemId
                                            }`)
                                            window.location.reload(true);
                                          }}}
                                            to={localStorage.getItem('t')?`/LTWeekly/${moment(
                                              item.ItemName,
                                              "MM DD YYYY"
                                            ).format("MMMM-DD-YYYY")}/${
                                              it.ItemId
                                            }`:'#'}
                                            state={{
                                              data: item,
                                              isFrom: "feed",
                                            }}
                                          >
                                           {/* {(year==="2024" || year==="2023")?<img src={it.Photo ? it.Photo:item.Photo} className="archives-list-img"/>:""} */}
                                            <span className="archives-list-number">{j + 1}.</span>
                                            {getThemeTitle(
                                              get(it, "ShortDescription", "")
                                            )}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                </ol>
                              </li>
                              {get(item, "Stock", "") !== "" &&
                                get(item, "Stock", "") !== null && (
                                  <li
                                    key={get(item, "ItemId", "") + 1}
                                    className="archives__topic--item"
                                  >
                                    <Link
                                      onClick={() =>{
                                        if(!localStorage.getItem("t")){
                                          openMailModal(`${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                            get(item, "ItemName", ""),
                                            "MM DD YYYY"
                                          ).format("MMMM-DD-YYYY")}/${get(
                                            item,
                                            "ItemId",
                                            ""
                                          )}/stock-check`)
                                        }else{
                                        linkClicked(
                                          "Stock Market Check",
                                          `/LTWeekly/${moment(
                                            get(item, "ItemName", ""),
                                            "MM DD YYYY"
                                          ).format("MMMM-DD-YYYY")}/${get(
                                            item,
                                            "ItemId",
                                            ""
                                          )}/stock-check`,
                                          get(item, "ItemId", "")
                                        )
                                      navigate(`/LTWeekly/${moment(
                                        get(item, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        item,
                                        "ItemId",
                                        ""
                                      )}/stock-check`)
                                      window.location.reload(true);
                                    }}}
                                    to={localStorage.getItem('t')?`/LTWeekly/${moment(
                                        get(item, "ItemName", ""),
                                        "MM DD YYYY"
                                      ).format("MMMM-DD-YYYY")}/${get(
                                        item,
                                        "ItemId",
                                        ""
                                      )}/stock-check`:'#'}
                                      state={{ data: item, isFrom: "feed" }}
                                      className="archives__topic--link "
                                    >
                              {/* {(year==="2024" || year==="2023")? <img src={item.Stock} className="archives-list-img"/>:""} */}
                                      Stock Market Check
                                    </Link>
                                  </li>
                                )}
                              {
                              // get(item, "OtherNews", []).length > 0 &&
                               (
                                <li
                                  key={get(item, "ItemId", "") + 2}
                                  className="archives__topic--item"
                                >
                                  <Link
                                    onClick={() =>{
                                      if(!localStorage.getItem("t")){
                                        openMailModal( `${process.env.REACT_APP_SITE_URL}/LTWeekly/${moment(
                                          get(item, "ItemName", ""),
                                          "MM DD YYYY"
                                        ).format("MMMM-DD-YYYY")}/${get(
                                          item,
                                          "ItemId",
                                          ""
                                        )}/sub-sector`)
                                      }else{
                                      linkClicked(
                                        "This Week's Other Curated News",
                                        `/LTWeekly/${moment(
                                          get(item, "ItemName", ""),
                                          "MM DD YYYY"
                                        ).format("MMMM-DD-YYYY")}/${get(
                                          item,
                                          "ItemId",
                                          ""
                                        )}/sub-sector`,
                                        get(item, "ItemId", "")
                                      )
                                    
                                    navigate(`/LTWeekly/${moment(
                                      get(item, "ItemName", ""),
                                      "MM DD YYYY"
                                    ).format("MMMM-DD-YYYY")}/${get(
                                      item,
                                      "ItemId",
                                      ""
                                    )}/sub-sector`)
                                    window.location.reload(true);
                                  }}}
                                  to={localStorage.getItem('t')?`/LTWeekly/${moment(
                                      get(item, "ItemName", ""),
                                      "MM DD YYYY"
                                    ).format("MMMM-DD-YYYY")}/${get(
                                      item,
                                      "ItemId",
                                      ""
                                    )}/sub-sector`:'#'}
                                    state={{ data: item, isFrom: "feed" }}
                                    className="archives__topic--link"
                                  >
                                    This Week's Other Curated News
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        );
                      })
                    )}
                  </>
                </div>
                <div className="feed__footer">
            {!isLoader && !(totalRecords === archiveList.length) && (
              <button
                onClick={() => loadMore()}
                className="btn btn-lg btn__purple"
              >
                {isLoading ? <Spin indicator={antIcon} /> : "load more"}
              </button>
             )}
          </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ReactModal
                                      isOpen={mailModalIsOpen}
                                      // onAfterOpen={afterOpenModal}
                                      onRequestClose={closeMailModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={true}
                                      shouldCloseOnEsc={true}
                                      onAfterOpen={handleAfterOpenModal}
                                      className="social-media-modal social-media-modal-ht ms-0"
                                        portalClassName="react-modal- email-media-modal"
                                        style={{
                                          overlay: {
                                            backgroundColor: "rgba(0, 0, 0, 0.75)"
                                          }
                                        }}
                                    >
                                      <div class="flex-wrap w-100">
                                        <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                          <h5 className="header-title">Access Theme</h5>
                                          {/* <button
                                            onClick={closeMailModal}
                                            className="btn btn__cookies p-0"
                                          >
                                            <img src={IcClose} alt="" />
                                          </button> */}
                                        </header>

                                    

                                        <div className="bluebg">
                                          <label className="custom-label">Email Address</label>
                                          <input type="text" className="custom-input"
                                          ref={emailInputRef}
                                          onChange={(e) =>
                                            handleChangeEmail(e.target.value)
                                          } 
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              checkUser(userEmail, themeLink);
                                            }
                                          }}
                                          />
                                        </div>
                                        <div style={{ textAlign: "left", color: "red", paddingLeft:"20px"}}>
                                        {errorMessage && (
                                          <>
                                         {errorMessage}
                                         </>
                                        )}
                                        </div>
                                        <div className="text-end">
                                        <button className="bluebtn MinWidthLogin"
                                        disabled={isButtonDisabled}
                                        onClick={()=>checkUser(userEmail,themeLink)}>
                                            {emailloader ? (
                                        <Spin indicator={antIcon} className="CustomSize" />
                                         ) : (
                                         "Login"
                                          )}
                                           </button>
                                        
                                        </div>
                                        <div className="text-center">
                                          <p className="gated-popoup"><a href="https://liontreeuat.wpenginepowered.com/ltweekly/?inboundpath=themeweb">Click here</a> if you are not on the list of subscribers.</p>
                                        </div>
                                      </div>
                                    </ReactModal>
                                    {/* <ReactModal
                                      isOpen={successModalIsOpen}
                                      onRequestClose={closeSuccessModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={true}
                                      shouldCloseOnEsc={true}
                                      className="social-media-modal social-media-modal-ht ms-0"
                                      style={{
                                        overlay: {
                                          backgroundColor: "rgba(0, 0, 0, 0.75)",
                                          zIndex: '999',
                                        }
                                      }}
                                    >
                                      <div class="flex-wrap w-100">
                                        <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                        <h5 className="header-title">Check your email</h5>
                                        </header>
                                        <div className="bluebg">
                                            <p className="mb-0 parastyle">We sent an email to you at {userEmail}. It has a magic link that will sign you in.</p>
                                        </div>
                                        <div className="text-end">
                                        <button className="closebtn2"
                                        onClick={()=>closeSuccessModal()}>Close</button>
                                        </div>
                                      </div>
                                    </ReactModal> */}
                                    <ReactModal
                                      isOpen={isAcknwoledgmentModalOpen}
                                      // onAfterOpen={afterOpenModal}
                                      onRequestClose={closeAcknowledgmentModal}
                                      ariaHideApp={false}
                                      shouldFocusAfterRender={true}
                                      shouldCloseOnOverlayClick={false}
                                      shouldCloseOnEsc={true}
                                      className="social-media-modal social-media-modal-ht ms-0"
                                      style={{
                                        overlay: {
                                          backgroundColor: "rgba(0, 0, 0, 0.75)",
                                          zIndex: '999',
                                        }
                                      }}
                                    >
                                      <div class="flex-wrap w-100">
                                      <header className="custom-header d-flex align-items-center justify-content-between mb-16">
                                        <h5 className="header-title">Hi There!</h5>
                                          {/* <button
                                            onClick={closeSuccessModal}
                                            className="btn btn__cookies p-0"
                                          >
                                            <img src={IcClose} alt="" />
                                          </button> */}
                                        </header>
                                        <div className="bluebg">
                                            <p className="mb-0 parastyle">A LionTree member will review your request and respond within 24-48 hours.</p>
                                        </div>
                                        <div className="text-end">
                                        <button className="closebtn2"
                                        onClick={()=>closeAcknowledgmentModal()}>OK</button>
                                        </div>
                                      </div>
                                    </ReactModal>
    </>
  );
}
